/* 
// 08. Features
*/

.features-box {
    background: rgba($white, 0.025);
    border-radius: 6px;
    .features-icon {
        background: $warning;
        display: inline-block;
        height: 50px;
        width: 50px;
        line-height: 50px;
        text-align: center;
        color: $white;
        border-radius: 6px;
        font-size: 30px;
        position: relative;
        &:before {
            content: "";
            height: 60px;
            width: 60px;
            background: rgba($warning, 0.1);
            position: absolute;
            left: 7px;
            top: -16px;
            transform: rotate(20deg);
            z-index: -1;
            border-radius: 6px;
        }
    }
}